import { template as template_e8fd9201f6084d16af6cc776c484138f } from "@ember/template-compiler";
const FKControlMenuContainer = template_e8fd9201f6084d16af6cc776c484138f(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
