import { template as template_8db65efb79e148ef81799ec437d4a648 } from "@ember/template-compiler";
const FKLabel = template_8db65efb79e148ef81799ec437d4a648(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
