import { template as template_fd21f589f08c4a21b2962b116a530180 } from "@ember/template-compiler";
const FKText = template_fd21f589f08c4a21b2962b116a530180(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
