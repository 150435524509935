import { template as template_882daf10d2b54765b7e7e6f1b3695427 } from "@ember/template-compiler";
const WelcomeHeader = template_882daf10d2b54765b7e7e6f1b3695427(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
